<template>
  <div class="mb-2">
    <card-one v-if="['card-1', 'card-2'].includes(section.style)" :isPreview="isPreview" :getBioLinkAdds="getBioLinkAdds" :card="section"></card-one>
    <thumb-one v-if="['thumb-1', 'thumb-2'].includes(section.style)" :isPreview="isPreview" :getBioLinkAdds="getBioLinkAdds" :card="section"></thumb-one>
    <strip v-if="section.style==='strip'" :isPreview="isPreview" :getBioLinkAdds="getBioLinkAdds" :card="section"></strip>
  </div>

</template>
<script>


export default ({
  components: {
    'card-one': () => import('../bio-common-components/style/CardOne'),
    'thumb-one': () => import('../bio-common-components/style/ThumbOne'),
    'strip': () =>import('../bio-common-components/style/Strip')
  },
  props: [
    'isPreview',
    'section',
    'getBioLinkAdds'
  ],
  methods: {},
})
</script>

